<template>
	<div class="news_mian">
		<!-- <div class="online">
		<div class="contain">
			<h1 class="htitle">欢迎查询</h1>
			<p>应届毕业生每年八月份开始办理就业报到手续，报到时间地点：每周一到周五（上午 8：00—11：30 下午 14：00—17：00 夏令 下午
				14：30—17：30），<br />临海市河阳路319号(6楼)，人才交流中心人事代理部服务窗口。咨询电话：0576-85159677，85288196</p>
			<div class="ibox">
				<div class="ibox-left">
					<h2>档案查询</h2>
					<p>（存档机构选择：<b>临海</b>）</p>
					<p>（出生年份格式：<b>1990或19901201或1990-12-01</b>）<br>注：因系统升级数据过渡，假如查询不到，请拨打电话：0576-85159677</p>
				</div>
				<div class="ibox-right"><iframe width="100%" src="https://sp.tzrcsc.cn/dangan.aspx"
						height="450"></iframe></div>
			</div>
		</div>
	</div> -->
		<div class="cont_mian">
			<div class="left">
				<div class="newstype">
					<h3>项目办理</h3>
					<div v-for="item in newslist" :key="item.id" class="type_mian"
						:class="info.id === item.id ? 'checket' : ''" @click="changType(item.id)">
						<span class="typename">{{ item.cTITLE }}</span>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="title">{{ info.cTITLE }}</div>
				<div class="new_mian">
					<div v-html="info.cCONTENTS"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import {
		Getnewtypelist,
		Getnewslist,
		GetHotnewslist
	} from "@/api/home";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				selfrom: {
					types: "45",
					pageNumber: 1,
					pageSize: 20,
				},
				total: 0,
				info: {},
				newslist: [],
			};
		},
		created() {
			this.GetNewList();
		},
		methods: {
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			GetNewList() {
				var _this = this;
				Getnewslist(this.selfrom).then((res) => {
					if (res.success) {
						_this.newslist = res.data.rows;
						_this.newslist.findIndex((res) => {
							_this.info = res;
						});
					}
				});
			},
			changType(id) {
				var _this = this;
				_this.newslist.forEach((res) => {
					if (res.id === id) {
						_this.info = res;
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.online {
		background: url(../../assets/img/online-bg.png) no-repeat;
		background-size: cover;
		height: calc(100vh - 125px);
		display: flex;
		align-items: center;
		justify-content: center;

		.contain {
			text-align: center;

			.htitle {
				color: #fff;
			}

			.ibox {
				margin: auto;
				padding: 30px;
				width: 1200px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				background-color: #eee;

				.ibox-left {
					width: 35%;

					h2 {
						color: #010101;
					}

					p {
						color: #010101;
						font-size: 12px;
						line-height: 2;
					}
				}

				.ibox-right {
					width: 65%;

					iframe {
						background-color: #fff;
						border: 1px dashed #276bf2;
					}
				}
			}
		}

		.contain>p {
			color: #fff;
			font-size: 13px;
			line-height: 2;
		}
	}


	.news_mian {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
	}

	.cont_mian {
		display: flex;
		justify-content: space-between;

		.left,
		.right {
			min-height: calc(100vh - 310px);
			margin-top: 15px;
		}

		.left {
			width: 280px;
			font-size: 14px;

			.checket {
				// border-left: 5px solid #409eff;
				color: #409eff;

				.typename {
					margin-left: 0px;
				}
			}

			.newstype {}

			.type_mian {
				cursor: pointer;
				padding: 10px 25px;
				background-color: #fff;
				// margin: 10px 0;
			}

			.type_mian:hover {
				color: #409eff;
			}

			.typename {
				margin-left: 0px;
			}
		}

		.right {
			background-color: #fff;
			padding: 10px;
			width: 880px;

			.title {
				font-size: 16px;
				font-weight: bold;
				padding: 20px;
				border-bottom: 1px solid #dcdfe6;
				margin-bottom: 10px;
			}

			.new_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 5px;
				font-size: 14px;
				cursor: pointer;

				.titles {
					display: flex;
					align-items: center;
				}

				.yuan {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #ea5404;
					margin: 0 15px;
				}
			}

			.new_item:hover {
				color: #409eff;
			}
		}
	}
</style>
